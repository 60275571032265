import { Navigate } from "react-router-dom";
import { useSession } from "./SessionContext";
import dayjs from "dayjs";

export const ProtectedRoute = ({ children }: any) => {
    let { session, setError } = useSession();

    let isAuthenticated = true;
    let error = "";

    console.log("checking protected route")

    if (!session?.token) {
        error = "Session not found"
        isAuthenticated = false;
    }

    if (dayjs().isAfter(dayjs(session?.token?.expires))) {
        isAuthenticated = false;
        error = "Token expired. Please login again"
    }

    if (!isAuthenticated) {
        setError(error)
        return <Navigate to="/login" />;
    }

    return children;
};