import React, { useEffect, useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import Table from "./components/Table";
import TicketRefundModal from './components/TicketRefundModal';
import TicketResendModal from "./components/TicketResendModal";
import InfoModal from "./components/ListTicketInfoModal";
import ScannedBarGraph from "./ScannedBarGraph";
import { getData, deleteData, postData } from './services/api';
import dayjs from "dayjs";
import { useSession } from './assets/auth/SessionContext';
import { Search } from 'lucide-react';

import datePicker from "./utils/date-picker";
import models from './utils/models';

interface Ticket {
    ref: string;
    isScanned: boolean;
    transactions: any;
    email: string;
    deletedAt: Date
}

interface Event {
    name: string;
    ref: string;
}

interface StatusOfTickets {
    name: string;
    ref: string;
}

const statusOfTickets: StatusOfTickets[] = [
    { name: "Paid", ref: "paid" },
    { name: "Deposit", ref: "deposit" }
];

const TicketManagement: React.FC = () => {
    const { session } = useSession();
    const [data, setData] = useState<Ticket[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [dateFrom, setDateFrom] = useState<Date>(new Date());
    const [dateTo, setDateTo] = useState<Date>(dayjs().add(1, "month").toDate());
    const [events, setEvents] = useState<Event[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
    const [selectedStatusOfTickets, setSelectedStatusOfTickets] = useState<StatusOfTickets>(statusOfTickets[0]);
    const [ticketElements, setTicketElements] = useState<React.ReactElement | null>(null);
    const [email, setEmail] = useState("");
    const [emailTemp, setEmailTemp] = useState("");

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    });

    const toast = useRef<Toast>(null);
    const dependencies = [selectedEvent, selectedStatusOfTickets, email];
    const dateFormat = 'dd-mm-yy';

    const fetchEvents = () => {
        getData(session, `events`, `dateFrom=${dayjs(dateFrom).format("YYYY-MM-DD")}&dateTo=${dayjs(dateTo).format("YYYY-MM-DD")}&dropdown=true`)
            .then((events: Event[]) => {
                setEvents(events);
            })
            .catch(err => setError(err.message));
    };

    const fetchTrackTicketScanned = (eventRef: string | null, statusOfTicketRef: string) => {

        if (selectedEvent && statusOfTicketRef === "paid") {
            getData(session, "reports/track-tickets-scanned", `eventRef=${eventRef}`)
                .then(scannedData => {
                    setTicketElements(scannedData.length > 0 ? <ScannedBarGraph data={scannedData} /> : null);
                })
                .catch(err => setError(err.message));
        } else {
            setTicketElements(null);
        }
    };

    const fetchTicketsLazy = async (lazyParams: any) => {

        if ((selectedEvent && selectedStatusOfTickets) || email) {
            const { page, rows } = lazyParams;
            const result = await getData(session, `tickets/event/${selectedEvent}`, `ticketStatus=${selectedStatusOfTickets.ref}&email=${email}&page=${page}&rows=${rows}`);
            setData(result.items);
            return result;
        }

        return {};
    };

    const refreshTable = () => {
        setLazyParams({ ...lazyParams });
    };

    const handleSearch = () => {
        setSelectedEvent(null);
        setEmail(emailTemp);
    };

    useEffect(() => {
        fetchEvents();
    }, [dateFrom, dateTo, session]);

    const onDateChange = (fieldName: string, value: Date | null) => {
        datePicker.max1Month({ fieldName, value, from: dateFrom, to: dateTo, setFrom: setDateFrom, setTo: setDateTo });

        setSelectedEvent(null);
        setTicketElements(null);
    };

    const onEventChange = (eventRef: string) => {

        setData([]);
        setEmailTemp("");
        setEmail("");
        setSelectedEvent(eventRef);
        fetchTrackTicketScanned(eventRef, selectedStatusOfTickets.ref);
    };

    const onStatusChange = (statusOfTicketRef: StatusOfTickets) => {
        setSelectedStatusOfTickets(statusOfTicketRef);

        setSelectedEvent(null);
        setEmailTemp("");
        setEmail("");
        setData([]);

        setLazyParams({
            ...lazyParams,
            first: 0,
            page: 0,
        });
    };

    const remove = async (ref: string) => {
        try {
            await deleteData(session, `tickets/${ref}`, null);

            return { success: true, message: "Ticket removed" };
        } catch (err: any) {
            return { success: false, message: err.message };
        }
    };

    const shouldDisableButton = (rowData: Ticket, id: string) => {
        if (id === "scan") {
            return !(rowData.isScanned === false && selectedStatusOfTickets.ref === "paid" && !rowData.deletedAt);
        }
        if (id === "refund") {
            const isRefundable = rowData.transactions?.some((x: any) => x.paymentType === "paymentLink") && !rowData.deletedAt;
            return !isRefundable;
        }

        if (id === "email" && (selectedStatusOfTickets.ref === "deposit" || rowData.deletedAt)) {
            return true;
        }

        if (id === "remove" && (rowData.deletedAt || selectedStatusOfTickets.ref === "deposit")) {
            return true;
        }

        return false;
    };

    const shouldRemoveButton = (rowData: Ticket, id: string) => {

        if (id === "info" && !rowData.deletedAt) {
            return false;
        }

        return true;
    };

    const [scanIsVisible, setScanIsVisible] = useState<{ scan: boolean }>({ scan: false });
    const [refundIsVisible, setRefundIsVisible] = useState(false);
    const [resendIsVisible, setResendIsVisible] = useState(false);
    const [infoModalIsVisible, setInfoModalIsVisible] = useState(false);
    const [removeIsVisible, setRemoveIsVisible] = useState(false);
    const [selectedScanItem, setSelectedScanItem] = useState<string | null>(null);
    const [selectedRemoveItem, setSelectedRemoveItem] = useState<string>("");
    const [selectedRefundItem, setSelectedRefundItem] = useState<string | null>(null);
    const [selectedResendItem, setSelectedResendItem] = useState<string | null>(null);
    const [selectedInfoItem, setSelectedInfoItem] = useState<string | null>(null);

    const handleClick = (id: string, ref: string) => setScanIsVisible({ ...scanIsVisible, [id]: true });
    const handleScanHide = () => setScanIsVisible({ ...scanIsVisible, scan: false });

    const handleRefundHide = (update: any | null) => {
        setRefundIsVisible(false);

        if (toast.current && update.severity) {
            toast.current.show({ severity: update.severity, summary: update.summary, detail: "" });
        }
    };

    const handleResendHide = (update: any | null) => {
        setResendIsVisible(false);

        if (toast.current && update.severity) {
            toast.current.show({ severity: update.severity, summary: update.summary, detail: "" });
        }
    }

    const handleRemoveHide = () => {
        setRemoveIsVisible(false);
    }

    const handleTicketListInfoHide = (update: any | null) => {
        setInfoModalIsVisible(false);
    }

    const confirmScan = () => {
        if (selectedScanItem && selectedEvent) {
            postData(session, `tickets/scan/${selectedScanItem}`, { eventRef: selectedEvent }, null)
                .then(() => {
                    refreshTable();
                    setScanIsVisible({ ...scanIsVisible, scan: false });
                })
                .catch(err => toast.current?.show({ severity: 'error', summary: 'Error Message', detail: err.message }));
        }
    };

    const confirmRemove = async () => {
        setRemoveIsVisible(false);
        const removeResult = await remove(selectedRemoveItem);

        if (removeResult.success === true && toast.current) {
            refreshTable();
            toast.current.show({ severity: 'success', summary: "Removed successfully", detail: "" });
        } else if (removeResult.success === false && toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: removeResult.message });
        }
    };

    const handleScanClick = (data: Ticket) => {
        setSelectedScanItem(data.ref);
        handleClick('scan', data.ref);
    };

    const handleRefundClick = (data: Ticket) => {
        setSelectedRefundItem(data.ref);
        setRefundIsVisible(true);
    };

    const handleRemoveClick = (data: Ticket) => {
        setSelectedRemoveItem(data.ref);
        setRemoveIsVisible(true);
    };

    const handleEmailClick = (data: Ticket) => {
        setSelectedResendItem(data.ref);
        setResendIsVisible(true);
    };

    const handleInfoClick = (data: Ticket) => {
        setSelectedInfoItem(data.ref);
        setInfoModalIsVisible(true);
    }

    const customButtons = [
        { icon: 'pi pi-trash', onClick: handleRemoveClick, id: "remove" },
        { icon: 'pi pi-money-bill', onClick: handleRefundClick, id: "refund" },
        { icon: 'pi pi-bookmark', onClick: handleScanClick, id: "scan" },
        { icon: "pi pi-envelope", onClick: handleEmailClick, id: "email" },
        { icon: "pi pi-info-circle", onClick: handleInfoClick, id: "info", style: { color: "red" } }
    ];

    const customModals = [
        {
            modal: (
                <ConfirmDialog
                    visible={removeIsVisible}
                    onHide={handleRemoveHide}
                    message="Are you sure you want to delete this item?"
                    header="Confirmation"
                    icon="pi pi-exclamation-triangle"
                    accept={confirmRemove}
                    reject={handleRemoveHide}
                    acceptClassName="confirm-dialog-yes"
                    rejectClassName="confirm-dialog-no"
                />
            )
        },
        {
            modal: (
                <ConfirmDialog
                    visible={scanIsVisible.scan}
                    onHide={handleScanHide}
                    message="Are you sure you want to scan this item?"
                    header="Scan Ticket"
                    icon="pi pi-exclamation-triangle"
                    accept={confirmScan}
                    reject={handleScanHide}
                    acceptClassName="confirm-dialog-yes"
                    rejectClassName="confirm-dialog-no"
                />
            )
        },
        {
            modal: (
                <Dialog visible={refundIsVisible} onHide={() => { }}>
                    <TicketRefundModal
                        selectedDataItem={data.find(item => item.ref === selectedRefundItem)?.transactions}
                        closeModal={handleRefundHide}
                    />
                </Dialog>
            )
        },
        {
            modal: (
                <Dialog visible={resendIsVisible} onHide={() => { }}>
                    <TicketResendModal
                        selectedDataItem={data.find(item => item.ref === selectedResendItem)}
                        closeModal={handleResendHide}
                    />
                </Dialog>
            )
        },
        {
            modal: (
                <Dialog visible={infoModalIsVisible} onHide={() => { }}>
                    <InfoModal
                        selectedDataItem={data.find(item => item.ref === selectedInfoItem)}
                        closeModal={handleTicketListInfoHide}
                    />
                </Dialog>
            )
        }
    ];

    const customRowBodyTemplate = (rowData: Ticket) => {
        if (rowData.isScanned != null) {
            return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.isScanned, 'text-red-500 pi-times-circle': !rowData.isScanned })}></i>;
        }
    };

    return (
        <div className="flex">
            <Toast ref={toast} />
            <div className="table-container">
                <div>
                    <div className="card flex flex-wrap gap-3 p-fluid select-dates">
                        <div className="flex1">
                            <label htmlFor="buttondisplay" className="font-bold block mb-2">From</label>
                            <Calendar dateFormat={dateFormat} className="calendar" value={dateFrom} onChange={(e) => onDateChange("from", e.value as Date)} showIcon />
                        </div>
                        <div className="flex1">
                            <label htmlFor="buttondisplay" className="font-bold block mb-2">To</label>
                            <Calendar value={dateTo} dateFormat={dateFormat} className="calendar" onChange={(e) => onDateChange("to", e.value as Date)} showIcon />
                        </div>
                        <div className="flex1 top-bar-filtering">
                            <label htmlFor="text-label" className="font-bold block mb-2">Select ticket status</label>
                            <Dropdown
                                onChange={(e) => onStatusChange(e.value as StatusOfTickets)}
                                options={statusOfTickets}
                                optionLabel="name"
                                className="dropdown"
                                value={selectedStatusOfTickets}
                            />
                        </div>
                        <div className="flex1 top-bar-filtering">
                            <label htmlFor="text-label" className="font-bold block mb-2">Select event</label>
                            <Dropdown
                                onChange={(e) => onEventChange(e.value as string)}
                                options={events.map(event => ({ label: event.name, value: event.ref }))}
                                placeholder="Select event"
                                className="dropdown"
                                value={selectedEvent}
                            />
                        </div>
                    </div>
                    <div className="card flex flex-wrap gap-3 p-fluid select-dates">

                        <div className="flex1">
                            <label htmlFor="buttondisplay" className="font-bold block mb-2">E-Mail</label>

                            <input
                                className="text-input"
                                type="text"
                                id="email"
                                name="email"
                                value={emailTemp}
                                onChange={(e) => setEmailTemp(e.target.value)}
                            />
                        </div>
                        <div className="flex1">
                            <label className="font-bold block mb-2" style={{ height: 24 }}></label>
                            <div className={"rounded-md cursor-pointer py-2 px-3 my-1 ticket-management-lance"} onClick={handleSearch}> <Search size={20} /></div>
                        </div>
                    </div>
                </div>

                <div>
                    {ticketElements}
                    <div className="table-container">
                        <Table
                            type="tickets"
                            shouldDisableButton={shouldDisableButton}
                            shouldRemoveButton={shouldRemoveButton}
                            remove={remove}
                            editButtonVisible={false}
                            removeButtonVisible={false}
                            addButtonVisible={false}
                            customButtons={customButtons}
                            customModals={customModals}
                            customRowBodyTemplate={customRowBodyTemplate}
                            exportButtonVisible={true}
                            map={models.ticketsMap()}
                            loadFunction={fetchTicketsLazy}
                            lazy={true}
                            dependencies={dependencies}
                            lazyParams={lazyParams}
                            setLazyParams={setLazyParams}
                            showFilters={false}
                            refreshTable={refreshTable}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TicketManagement;
