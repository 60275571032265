import { Routes, Route, Navigate } from "react-router-dom";
import Login from './Login';
import Home from './Home';
import Users from "./Users";
import Teams from "./Teams";
import Roles from "./Roles";
import DeviceUsers from "./DeviceUsers";
import Events from "./Events";
import EventCategories from "./EventCategories"
import Permissions from "./Permissions"
import RouteResources from "./RouteResources"
import TicketTypes from "./TicketTypes"
import TicketTypeGroups from "./TicketTypeGroups"
import TicketManagement from "./TicketManagement";
import CreateTicket from "./CreateTicket";
import PageNotFound from "./PageNotFound";
import TicketSales from "./TicketSales";
import SellersProgress from "./SellersProgress";
import CashOuts from "./CashOuts";

import Layout from './layout/Layout';

import { SessionProvider } from './assets/auth/SessionContext';
import { ProtectedRoute } from "./assets/auth/ProtectedRoute"

export default function App() {

    return (
        <SessionProvider>
            <Routes>
                <Route path="/login" element={<Login />} />

                <Route path='*' element={<PageNotFound />} />

                <Route path="/" element={<Layout />} >

                    <Route index element={<Navigate to="home" />} />

                    <Route path='home' element={
                        <ProtectedRoute>
                            <Home />
                        </ProtectedRoute>
                    } />
                    <Route path="users" element={
                        <ProtectedRoute>
                            <Users />
                        </ProtectedRoute>
                    } />
                    <Route path="teams" element={
                        <ProtectedRoute>
                            <Teams />
                        </ProtectedRoute>
                    } />
                    <Route path="device-users" element={
                        <ProtectedRoute>
                            <DeviceUsers />
                        </ProtectedRoute>
                    } />
                    <Route path="roles" element={
                        <ProtectedRoute>
                            <Roles />
                        </ProtectedRoute>
                    } />
                    <Route path="events" element={
                        <ProtectedRoute>
                            <Events />
                        </ProtectedRoute>
                    } />
                    <Route path="event-categories" element={
                        <ProtectedRoute>
                            <EventCategories />
                        </ProtectedRoute>
                    } />
                    <Route path="ticket-types" element={
                        <ProtectedRoute>
                            <TicketTypes />
                        </ProtectedRoute>
                    } />
                    <Route path="ticket-type-groups" element={
                        <ProtectedRoute>
                            <TicketTypeGroups />
                        </ProtectedRoute>
                    } />
                    <Route path="permissions" element={
                        <ProtectedRoute>
                            <Permissions />
                        </ProtectedRoute>
                    } />
                    <Route path="route-resources" element={
                        <ProtectedRoute>
                            <RouteResources />
                        </ProtectedRoute>
                    } />
                    <Route path="ticket-management" element={
                        <ProtectedRoute>
                            <TicketManagement />
                        </ProtectedRoute>
                    } />
                    <Route path="create-ticket" element={
                        <ProtectedRoute>
                            <CreateTicket />
                        </ProtectedRoute>
                    } />
                    <Route path="ticket-sales" element={
                        <ProtectedRoute>
                            <TicketSales />
                        </ProtectedRoute>
                    } />
                    <Route path="sellers-progress" element={
                        <ProtectedRoute>
                            <SellersProgress />
                        </ProtectedRoute>
                    } />
                    <Route path="cash-outs" element={
                        <ProtectedRoute>
                            <CashOuts />
                        </ProtectedRoute>
                    } />
                </Route>
            </Routes>
        </SessionProvider >
    );
}

