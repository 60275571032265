import { Toast } from "primereact/toast";
import { useSession } from "../assets/auth/SessionContext";
import { useEffect, useRef, useState } from "react";
import { getData, postData, putData } from "../services/api";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Image } from 'primereact/image';
import { upload } from "../services/images";
import dayjs, { Dayjs } from "dayjs";
import CustomDateTimePicker from "../services/CustomDateTimePicker";

export default function EventModal(modalData: any) {

    const defaultDate = dayjs();
    defaultDate.hour(0);
    defaultDate.minute(0);

    const [formData, setFormData] = useState(modalData.selectedDataItem);
    const [dateFrom, setDateFrom] = useState<Dayjs | null>(defaultDate);
    const [dateTo, setDateTo] = useState<Dayjs | null>(defaultDate);
    const [selectedEventCategory, setSelectedEventCategory] = useState(null);
    const [genderTrackingOn, setGenderTrackingOn] = useState(false);
    const [active, setActive] = useState(false);
    const [modalHeader, setModalHeader] = useState("");
    const [imageBase64, setImageBase64] = useState("");

    const [eventCategories, setEventCategories] = useState([]);

    const toast = useRef<Toast>(null);

    const { session } = useSession();

    function save(data: any) {
        try {

            if (!data.name) {
                throw new Error("Name is required")
            }

            if (!data.location) {
                throw new Error("Location is required")
            }

            if (!data.capacity) {
                throw new Error("Capacity is required")
            }

            if (!dateFrom) {
                throw new Error("Date from is required")
            }

            if (!dateTo) {
                throw new Error("Date to is required")
            }

            if (!selectedEventCategory) {
                throw new Error("Event category to is required")
            }

            if (!imageBase64) {
                throw new Error("An image is required")
            }

            if (data.eventCode && data.eventCode.length !== 0 && data.eventCode.length < 6) {
                throw new Error("Event Code must be 6 characters long");
            }

            let event = {
                ref: data.ref,
                name: data.name,
                description: data.description,
                location: data.location,
                dateFrom: dayjs(dateFrom),
                dateTo: dayjs(dateTo),
                capacity: data.capacity,
                genderTracking: genderTrackingOn,
                active: active,
                eventCategoryRef: selectedEventCategory,
                image: imageBase64,
                ticketTypes: [],
                eventCode: data.eventCode,
                depositExpiryHours: data.depositExpiryHours,
                fullPaymentExpiryHours: data.fullPaymentExpiryHours
            } as any;

            if (modalData.mode === "update") {

                if (!event.eventCode) {
                    throw new Error("Event Code is required");
                }

                putData(session, `events/${event.ref}`, event, null).then(updateEventData => {

                    event.formatDate = dayjs(event.date).format("DD-MM-YYYY");

                    modalData.closeModal({ toast: true, message: `${event.name} updated`, updateRecord: event });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Event created', detail: "" });
                    }

                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }
                });
            } else if (modalData.mode === "create") {

                postData(session, `events`, event, null).then(newEventData => {

                    newEventData.data.formatDate = dayjs(event.date).format("DD-MM-YYYY");

                    modalData.closeModal({ toast: true, message: `${event.name} created`, newRecord: newEventData });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Event created', detail: "" });
                    }

                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }
                });
            }
        } catch (error: any) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.message });
            }
        }
    }

    const handleDateFromChange = (id: string, value: Dayjs | null) => {
        setDateFrom(dayjs(value));
    };

    const handleDateToChange = (id: string, value: Dayjs | null) => {
        setDateTo(dayjs(value));
    };

    const customBase64Uploader = async (event: any) => {
        const base64data = await upload(event);

        if (base64data) {
            setImageBase64(base64data.toString());
        }
    };

    useEffect(() => {
        if (modalData.mode === "create") {
            setModalHeader("Creating Event")
        } else {
            setModalHeader("Updating Event")
        }

        getData(session, `event-categories`, null).then(eventCategories => {

            const eventCategoriesForMultiSelect = eventCategories.map((x: { name: any; ref: any }) => ({
                name: x.name,
                value: x.ref
            }));

            setEventCategories(eventCategoriesForMultiSelect);

            if (modalData.mode === "update") {

                getData(session, `events/${formData.ref}`, null).then(event => {

                    setFormData(event);
                    setDateFrom(event.dateFrom ? dayjs(event.dateFrom) : dayjs());
                    setDateTo(event.dateTo ? dayjs(event.dateTo) : dayjs());
                    setImageBase64(event.image);
                    setGenderTrackingOn(event.genderTracking)
                    setActive(event.active);
                });
            }
        });
    }, []);

    useEffect(() => {
        if (eventCategories && eventCategories.length > 0) {
            setSelectedEventCategory(formData.eventCategoryRef);
        }
    }, [formData.eventCategoryRef, eventCategories]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (
        <div className="modal-container">

            <Toast ref={toast} />
            <div className="modal ">
                <div className="modal-header">
                    <span className="modal-title">{modalHeader}</span>
                    <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                </div>
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="name">Name</label>
                        <input
                            className="text-input"
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="name">Location</label>
                        <input
                            className="text-input"
                            type="text"
                            id="location"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="eventCategory">Event Category</label>
                        <Dropdown
                            onChange={(e) => { setSelectedEventCategory(e.value); }}
                            options={eventCategories}
                            optionLabel="name"
                            placeholder="Select event category"
                            className="dropdown"
                            value={selectedEventCategory} />
                    </div>
                </div>

                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3  select-dates remove-border-dates">
                        <label className="text-label" htmlFor="dateFrom">Date From</label>
                        <CustomDateTimePicker id="from" value={dateFrom} onChange={handleDateFromChange} />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 select-dates remove-border-dates">
                        <label className="text-label" htmlFor="dateTo">Date To</label>
                        <CustomDateTimePicker id="to" value={dateTo} onChange={handleDateToChange} />

                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">

                    </div>
                </div>

                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3  select-dates remove-border-dates">
                        <label className="text-label" htmlFor="depositExpiryHours">Deposit Link Expiry (hrs)</label>
                        <input
                            className="text-input-number"
                            type="number"
                            id="depositExpiryHours"
                            name="depositExpiryHours"
                            value={formData.depositExpiryHours}
                            onChange={handleChange}
                            min={0}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 select-dates remove-border-dates">
                        <label className="text-label" htmlFor="fullPaymentExpiryHours">Full payment Link Expiry (hrs)</label>
                        <input
                            className="text-input-number"
                            type="number"
                            id="fullPaymentExpiryHours"
                            name="fullPaymentExpiryHours"
                            value={formData.fullPaymentExpiryHours}
                            onChange={handleChange}
                            min={0}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">

                    </div>
                </div>

                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="capacity">Capacity</label>
                        <input
                            className="text-input-number"
                            type="number"
                            id="capacity"
                            name="capacity"
                            value={formData.capacity}
                            onChange={handleChange}
                            min={0}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="name">Gender Tracking</label>
                        <InputSwitch
                            checked={genderTrackingOn}
                            onChange={(e) => setGenderTrackingOn(e.value)}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="name">Active</label>
                        <InputSwitch
                            checked={active}
                            onChange={(e) => setActive(e.value)}
                        />
                    </div>
                </div>

                <div className="flex">

                    <div className="flex-1 flex m-2 px-5 py-3 image-container">
                        <label className="text-label" htmlFor="image">Image</label>

                        <Image
                            src={imageBase64}
                            zoomSrc={imageBase64}
                            alt="Image"
                            width="80"
                            height="60"
                            preview />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 select-time">
                        <label className="text-label" htmlFor="description">Description</label>
                        <textarea
                            className="text-input-multiline"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="eventCode">Scanning Code</label>
                        <input
                            className="text-input"
                            type="text"
                            id="eventCode"
                            name="eventCode"
                            value={formData.eventCode}
                            onChange={handleChange}
                            maxLength={6}
                        />
                    </div>

                </div>

                <div className="flex">
                    <div className="flex-1 uploader">
                        <FileUpload name="demo[]" accept=".jpeg,.jpg,.png" customUpload uploadHandler={customBase64Uploader} maxFileSize={1000000} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                    </div>

                </div>

                <div className="footer">
                    <button className="save-button" type="button" onClick={() => {
                        save(formData);
                    }
                    }>Save</button>
                    <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                </div>
            </div>
        </div >
    );
}